<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogEdit"
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h7"> EDITAR ESTADO DEL USUARIO </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4"
          >¿Esta seguro que desea <span class="red--text">DESACTIVAR</span> a
          este usuario?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light darken-1" rounded @click="dialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="primary darken-1" rounded @click="dialog = false">
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialogEdit: false,
      user_id: "",
    };
  },
  methods: {
    openDialog(user_id) {
      this.dialogEdit = true;
      this.user_id = user_id;
    },
  },
};
</script>
