<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <modal-edit-user ref="modalEditUser"></modal-edit-user>
        <modal-estado-user ref="modalEstadoUser"></modal-estado-user>
        <v-row>
          <v-col cols="12" sm="12" lg="2" md="4">
            <v-autocomplete
              :items="municipalities"
              item-text="descripcion"
              item-value="municipio_id"
              single
              label="Estado"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" lg="2" md="4">
            <v-autocomplete
              :items="territories"
              item-text="descripcion"
              item-value="territorio_id"
              single
              label="Rol"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" lg="2" md="4">
            <v-autocomplete
              :items="sidewalks"
              item-text="descripcion"
              item-value="vereda_id"
              single
              label="Ubicación"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" lg="2" md="4">
            <v-btn rounded color="primary" dark class="mt-2">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
                    'items-per-page-options': [5,10,15]
            
          }"
          :headers="headers"
          :items="desserts"
          :search="search"
          item-key="id"
        >
          <template v-slot:item.state="{ item }">
            <v-chip outlined color="primary">
              {{ item.state }}
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="showModalEditUser(item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Editar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="showModalEstadoUser(item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-progress-close</v-icon>
                    Desactivar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { syncDataSource } from "@/services/dataSource";
import modalEditUser from "./modalEditUser";
import modalEstadoUser from "./modalEstadoUser";
export default {
  name: "Users.vue",
  components: { modalEditUser, modalEstadoUser },
  data() {
    return {
      municipalities: [],
      territories: [],
      sidewalks: [],

      search: "",
      headers: [
        {
          text: "IDENTIFICACION",
          align: "start",
          sortable: false,
          value: "doc",
        },
        { text: "NOMBRE(S)", value: "name" },
        { text: "APELLIDO(S)", value: "lastname" },
        { text: "EMAIL", value: "email" },
        { text: "TELEFONO", value: "phone" },
        { text: "UBICACIÓN", value: "gps" },
        { text: "ROL", value: "rol" },
        { text: "ESTADO", value: "state" },
        { text: "ACCIÓN", value: "action" },
      ],
      desserts: [
        {
          doc: "1087241568",
          name: "carlos andres",
          lastname: "pillamues",
          email: "capillamues@gmail.com",
          phone: "3210221548",
          gps: "cabildo san juan, bolivar, el cauca",
          state: "ACTIVO",
          rol: "Encuestador",
        },
      ],
    };
  },
  methods: {
    showModalEditUser(id) {
      this.$refs.modalEditUser.openDialog(id);
    },
    showModalEstadoUser(id) {
      this.$refs.modalEstadoUser.openDialog(id);
    },
  },
  async created() {
    const response = await syncDataSource();
    this.municipalities = response.data.data.results.Municipio;
    this.territories = response.data.data.results.Territorio;
    this.sidewalks = response.data.data.results.Vereda;
  },
};
</script>

<style scoped></style>
