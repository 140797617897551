<template>
  <v-dialog max-width="550px" v-model="dialog">
    <v-card>
      <v-card-title>
        <span class="text-h6"> Editar Usuario </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <span class="text-h7 green--text"> Información del usuario </span>
          <v-row>
            <v-col cols="12" sm="12" lg="6" md="6">
              <v-text-field label="Nombre(s)" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" lg="6" md="6">
              <v-text-field label="Apellido(s)" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" lg="6" md="6">
              <v-text-field label="Correo electronico" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" lg="6" md="6">
              <v-text-field
                label="Documento de identidad"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" lg="6" md="6">
              <v-text-field label="Teléfono" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" lg="6" md="6">
              <v-select
                :items="roles"
                label="Rol"
                item-value="item"
                item-text="item"
                single
              ></v-select>
            </v-col>
          </v-row>

          <span class="text-h7 green--text"> Asignación de Ubicación </span>
          <v-row>
            <v-col cols="12" sm="12" lg="6" md="6">
              <v-autocomplete
                :items="territories"
                item-text="descripcion"
                item-value="territorio_id"
                single
                label="Territorio"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <span class="text-h7 green--text"> Información de seguridad </span>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-2"
                label="Contraseña"
                hint="minimo 8 caracteres"
                value=""
                class="input-group--focused"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-2"
                label="Confirmar Contraseña"
                hint="minimo 8 caracteres"
                value=""
                class="input-group--focused"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="light" rounded @click="dialog = false"> CANCELAR </v-btn>
        <v-btn color="primary" rounded> GUARDAR </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { syncDataSource } from "@/services/dataSource";
export default {
  data() {
    return {
      territories: [],
      roles: ["ADMINISTRADOR", "ESTANDAR"],
      dialog: false,
      user_id: "",
      showPassword: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    openDialog(user_id) {
      //this.$store.commit("LOADER", true);
      this.dialog = true;
      this.user_id = user_id;
      //await this.getOrganization();
      //this.$store.commit("LOADER", false);
    },
  },
  async created() {
    const response = await syncDataSource();
    this.territories = response.data.data.results.Territorio;
  },
};
</script>
